import "./login.scss";

import axios from "axios";
import React, { useRef, useState } from "react";
import { useCookies } from "react-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { withRouter, useHistory } from "react-router-dom";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

import { API_BASE_URL } from "../../constants/apiConstants";

function LoginForm(props) {
  // console.log("se props", props);
  let history = useHistory();
  const [state, setState] = useState({
    username: "",
    password: "",
    successMessage: null,
  });



  const [loginText, setLoginText] = React.useState("Sign in");
  const [loginState, setLoginState] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [rtoken, setRToken] = React.useState(null);
  const reCaptchaRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [cookies, setCookie] = useCookies(["name"]);
  const savedPostJob = window.localStorage.getItem("savedPostJob");
  const [showPassword, setShowPassword] = useState(false);

  const onRecaptchaChange = (value) => {
    setRToken(value);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value);
  };

  const redirectToHome = () => {
    props.updateTitle("Home");
    // props.history.push("/");
    if (history) { history.go(-1) }
    else {
      props.history.push("/");
    }
    window.location.href = "/";
  };

  const redirectToJobPosted = () => {
    props.updateTitle("Post a job");
    props.history.push("/");
    window.location.href = "/";
  };

  const redirectToPostJob = () => {
    props.updateTitle("Post a job");
    window.location.href = "/post-a-job";
  };

  const redirectToRegister = () => {
    props.history.push("/register");
    props.updateTitle("Register");
  };

  /* Redirecting to the password change page */

  const redirectToResetPassword = () => {
    window.location.href = "/reset-password";
    props.updateTitle("Reset-Password");
  };
  //

  const handleSubmitClick = (e) => {
    e.preventDefault();
    setLoginText("Signing in...");
    const payload = {
      username: props.username ? props.username : state.username,
      password: state.password,
      deviceid: uuidv4(),
    };

    console.log(payload);

    const loginAxios = axios.create({
      withCredentials: false,
      baseUrl: API_BASE_URL,
    });

    loginAxios
      .post(`${API_BASE_URL}/api/access/login`, payload)
      .then(function (response) {
        if (response.data.is_successful) {
          // setState((prevState) => ({
          //   ...prevState,
          //   successMessage: "Login successful. Redirecting to home page..",
          // }));

          setLoginText("Success...");

          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            // window.btoa(response.data.token_data.access_token),
            response.data.token_data.access_token,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          const customerType = response.data.user_data.ContentTypeAlias;

          if (customerType === "contactiCustomersMemberType") {
            sessionStorage.setItem("userType", "customer");
          } else {
            sessionStorage.setItem("userType", "serviceProvider");
          }

          setCookie("ContactiUsername", response.data.user_data.Username, {
            path: "/",
            expires: new Date(Date.now() + expirytime),
          });

          if (savedPostJob && savedPostJob.length > 0) {
            redirectToJobPosted();
          } else {

            redirectToHome();

          }


          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
          setLoginState("not verified");
          setLoginText("Verify");
        } else if (response.data.code === 204) {
          props.showError("მომხმარებელი და პაროლი არ ემთხვევა");
        } else {
          props.showError("მომხმარებელი ან პაროლი არასწორია");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoginText("ავტორიზაცია");
      });
  };

  const handlePinSubmitClick = (e) => {
    e.preventDefault();
    setLoginText("Verifying...");
    const payload = {
      email: state.username,
      password: state.password,
      pin: pin,
      token: rtoken,
    };

    const loginAxios = axios.create({
      withCredentials: false,
      baseUrl: API_BASE_URL,
    });

    loginAxios
      .post(`${API_BASE_URL}/api/access/confirm`, payload)
      .then(function (response) {
        if (response.data.is_successful) {
          setLoginText("Success...");

          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            // window.btoa(response.data.token_data.access_token),
            response.data.token_data.access_token,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          setCookie("ContactiUsername", response.data.user_data.Username, {
            path: "/",
            expires: new Date(Date.now() + expirytime),
          });

          if (savedPostJob && savedPostJob.length > 0) {
            redirectToJobPosted();
          } else {
            redirectToHome();
          }
          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
          setLoginText("Verify");
          setLoginState("not verified");
        } else if (response.data.code === 204) {
          props.showError("Username and password do not match");
        } else {
          props.showError("Pin Validation failed. Please enter valid pin.");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoginText("Verify");
      });
  };

  return (
    <div className="card col-12 col-lg-4 col-xl-3 login-card mt-2 hv-center">
      <div className="form-wrapper">
        <div className="login-header">
          <h4 className="fcaps">
            {loginState === "not verified"
              ? "Verify Registration"
              : "ავტორიზაცია"}
          </h4>
          <small>
            {loginState === "not verified"
              ? "Seems you have not completed your registration verification"
              : ""}
          </small>
        </div>
        {loginState === "not verified" ? (
          <form>
            <div className="form-inner">
              <div className="form-group text-left">
                <input
                  type="text"
                  id="regPin"
                  placeholder="4 Digit Pin"
                  //defaultValue={state.username}
                  value={pin}
                  onChange={handlePinChange}
                  className={`form-control ${!pin || pin.length < 4 || pin.length > 4 ? "error" : ""
                    }`}
                // {...register("username", { required: true, maxLength: 30 })}
                />
              </div>

              <div className="forgot-password">
                <span>
                  Please enter pin received via SMS to verify registration
                </span>
              </div>
              <div className="form-group text-left">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                  onChange={onRecaptchaChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary sign-in-btn"
              onClick={handlePinSubmitClick}
            >
              {loginText}
            </button>
          </form>
        ) : (
          <form>
            <div className="form-inner">
              <div className="form-group text-left">
                <input
                  type="text"
                  id="username"
                  placeholder="ელ-ფოსტა"
                  value={props.username ? props.username : state.username}
                  onChange={handleChange}
                  className={`form-control ${errors && errors.username ? "error" : ""
                    }`}
                // {...register("username", { required: true, maxLength: 30 })}
                />
              </div>
              <div className="form-group text-left">
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder="პაროლი"
                    value={state.password}
                    onChange={handleChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {showPassword ? (
                        <div className="input-group-addon">
                          <FaEyeSlash onClick={() => setShowPassword(false)} />
                        </div>
                      ) : (
                        <span>
                          <FaEye onClick={() => setShowPassword(true)} />
                        </span>
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div className="form-check"></div>

              <div className="forgot-password">
                <span> </span>
                <span
                  className="loginText"
                  onClick={() => redirectToResetPassword()}
                >
                  პაროლის აღდგენა
                </span>
              </div>
            </div>
            <div
              className="alert alert-success mt-2"
              style={{ display: state.successMessage ? "block" : "none" }}
              role="alert"
            >
              {state.successMessage}
            </div>
            <div className="register-message">
              <span>თუ გსურს იმუშაო ჩვენთან </span>
              <span className="loginText" onClick={() => redirectToRegister()}>
                დააჭირე აქ
              </span>
            </div>
            <div className="jobPost-message">
              <span>თუ გსურს მომსახურების მოთხოვნა </span>
              <span className="loginText" onClick={() => redirectToPostJob()}>
                დააჭირე აქ
              </span>
            </div>
            <button
              type="reset"
              className="btn btn-primary sign-in-btn fcaps"
              onClick={handleSubmitClick}
            >
              ავტორიზაცია
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default withRouter(LoginForm);
