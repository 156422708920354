import "./select-theme.scss";
import "react-phone-number-input/style.css";
import "react-phone-number-input/style.css";


import { isLength, isMatch } from "../reset-password/validation";
import { showErrMsg, showSuccessMsg } from "../reset-password/notification";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "react-select";
import axios from "axios";
import { useStateMachine } from "little-state-machine";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Controller, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { withRouter } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";

import { API_BASE_URL, TYPES } from "../../constants/apiConstants";
// import cities from './cities.json';
import SignUpHeader from "./sign-up-header";
import updateAction from "./updateAction";
import "./customer-registration.scss";
import { unstable_getThemeValue } from "@mui/system";


const initialState = {
  password: "",
  repeatPassword: "",
  err: "",
  success: "",
};

const AfterJobPostRegistration = (props) => {

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const { actions, state } = useStateMachine({ updateAction });
  const categoryObj = localStorage.getItem("reg_categories");
  const categoryOptions = JSON.parse(categoryObj);
  const mappedCategories = [];

  setTimeout(function () {
    categoryOptions.forEach((item, index) => {
      mappedCategories.push({
        name: item.Properties.jobCategoryName,
        id: item.Id,
      });
    });
  }, 1000);

  console.log("categoryOptions", mappedCategories);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    successMessage: null,
  });

  const [items, setItems] = React.useState([
    { label: "Loading ...", value: "" },
  ]);

  //Content from Local storage
  const regObj = localStorage.getItem("reg_labels");
  const regLabels = JSON.parse(regObj);

  const busObj = localStorage.getItem("businessTypeList");
  const businessTypeList = JSON.parse(busObj);

  const verifyObj = localStorage.getItem("mobileVerificationLabels");
  const mobileVerificationLabels = JSON.parse(verifyObj);

  const [loginText, setLoginText] = React.useState(regLabels.finishRegButton);
  const [newMobile, setNewMobile] = React.useState(state.mobileNumber);
  const [loginState, setLoginState] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [passwordEqual, setPasswordEqual] = useState(false);
  const [cookies, setCookie] = useCookies(["name"]);
  const [isCompany, setIsCompany] = useState(false);
  const [selectedJobCategory, setSelectedJobCategory] = useState("");
  const [selectedBusinessType, setBusinessType] = useState("");
  const [ltdAddress, setLtdAddress] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [confirmPayload, setConfirmPayload] = useState({});

  const [rtoken, setRToken] = React.useState(null);
  const reCaptchaRef = useRef();

  const savedPostJob = window.localStorage.getItem("savedPostJob");
  const savedPostJobJson = JSON.parse(savedPostJob);

  console.log("Zi errors", errors);

  state.type = TYPES.customer;

  const redirectToHome = () => {
    window.location.href = "/";
    props.updateTitle("Home");
  };

  const redirectToJobPosted = () => {
    window.location.href = "/job-posted";
    props.updateTitle("Post a job");
  };

  // const onRecaptchaChange = (value) => {
  //   setRToken(value);
  // };

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setNewMobile(value);
  };


  
  // ValidaTion 
  
  
  const [data, setData] = useState(initialState);

  const { password, repeatPassword, err, success } = data;

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setData({ ...data, [name]: value, err: "", success: "" });
  };

  // ValidaTion 



  const handlePinSubmitClick = (e) => {
    e.preventDefault();

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    setLoginText(regLabels.verifyingBtnLabel);

    console.log("confirmPayLoad", confirmPayload);

    const payload = {
      email: confirmPayload.email,
      mobileNumber: confirmPayload.mobileNumber,
      password: confirmPayload.password,
      pin: pin,
       token: rtoken,
    };

    const loginAxios = axios.create({
      withCredentials: false,
      baseUrl: API_BASE_URL,
    });

    loginAxios
      .post(`${API_BASE_URL}/api/access/confirm`, payload)
      .then(function (response) {
        if (response.data.is_successful) {
          setLoginText("Verified...");

          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            // window.btoa(response.data.token_data.access_token),
            response.data.token_data.access_token,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          setCookie("ContactiUsername", response.data.user_data.Username, {
            path: "/",
            expires: new Date(Date.now() + expirytime),
          });

          sessionStorage.setItem("userType", "customer");

          if (savedPostJob && savedPostJob.length > 0) {
            redirectToJobPosted();
          } else {
            redirectToHome();
          }
          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
          setLoginText(
            mobileVerificationLabels.mobileVerificationLabels.headerLabel
          );
          setLoginState("not verified");
        } else if (response.data.code === 204) {
          props.showError("Username and password do not match");
          setLoginText(
            mobileVerificationLabels.mobileVerificationLabels.headerLabel
          );
        } else {
          props.showError("Pin Validation failed. Please enter valid pin.");
          setLoginText(
            mobileVerificationLabels.mobileVerificationLabels.headerLabel
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const resendPin = (e) => {
    e.preventDefault();
    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    setLoginText("Sending...");
    axios
      .post(API_BASE_URL + "/api/access/resendverify", { email: state.email })
      .then(function (response) {
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
          setLoginState(mobileVerificationLabels.unverifiedLabel);
          setLoginText(mobileVerificationLabels.headerLabel);
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateNumber = (e) => {
    e.preventDefault();

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    const payload = {
      email: state.email,
      mobileNumber: newMobile,
       token: rtoken,
    };

    setLoginText(mobileVerificationLabels.sendingTextLabel);
    axios
      .post(API_BASE_URL + "/api/access/resendverify", payload)
      .then(function (response) {
        // setRToken(null);
        // reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
          setLoginState(mobileVerificationLabels.unverifiedLabel);
          setLoginText(mobileVerificationLabels.headerLabel);
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmite = (data) => {

    if (isLength(password)) {
      return setData({
        ...data,
        err: "პაროლი უნდა შედგებოდეს სულ მცირე 8 სიმბოლოსგან1",
        success: "",
      });
    }

    if (!isMatch(password, repeatPassword)) {
      return setData({
        ...data,
        err: "შეყვანილი პაროლები ერთმანეთს არ ემთხვევა",
        success: "",
      });
    }

    console.log("reeeeeg" + JSON.stringify(data));

    // console.log("rtoken" + rtoken);

    console.log("sssss:" + savedPostJob && savedPostJob.email);

    setConfirmPayload(data);

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    state.password = data.password;
    state.repeatPassword = data.repeatPassword;
    data.type = 1
    data.email = savedPostJobJson && savedPostJobJson.email;
    data.confirmEmail = savedPostJobJson && savedPostJobJson.email;
    data.firstName = savedPostJobJson && savedPostJobJson.fullName;
    data.lastName = savedPostJobJson && savedPostJobJson.fullName;

    setLoginText(regLabels.validatingBtnLabel);
    setDisabled(true);

    console.log("to be sent", state);
    console.log("data:", data);
    axios
     .post(API_BASE_URL + "/api/access/register", { ...data, token: rtoken })
     // .post(API_BASE_URL + "/api/access/register", { ...data })
      .then(function (response) {
        // setRToken(null);
        // reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setDisabled(false);

          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (
          response.data.failed_reason === "Waiting for user verification"
        ) {
         
          setLoginState("not verified");
          setLoginText("დადასტურება");
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="card col-12 cust-reg">
      <div className="form-wrapper">
        {loginState === "not verified" ? (
          <form>
            <div className="sign-up-header"></div>
            <div className="form-inner">
              <div className="form-group text-left">
                <input
                  type="text"
                  id="regPin"
                  placeholder={regLabels.pinLabel}
                  value={pin}
                  onChange={handlePinChange}
                  className={`form-control ${
                    !pin || pin.length < 4 || pin.length > 4 ? "error" : ""
                  }`}
                />
              </div>
              <div className="forgot-password">
                <span>
               {mobileVerificationLabels.resendText}{" "}
                  <a href="#" onClick={resendPin}>
                    {mobileVerificationLabels.resendBtnLabel}
                  </a>{" "}
                  {mobileVerificationLabels.orTextLabel}{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setLoginState("update number");
                    }}
                  >
                    {mobileVerificationLabels.updateMobileLabel}
                  </a>{" "}
                </span>
              </div>

              {/* <div className="form-group text-left">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                  onChange={onRecaptchaChange}
                />
              </div> */}
            </div>
            <button
              type="submit"
              className="btn btn-primary sign-in-btn verify-btn"
              onClick={handlePinSubmitClick}
            >
              {loginText}
            </button>
          </form>
        ) : (
          <>
            <div className="form-group text-left">
              <Controller
                name="mobileNumber"
                control={control}
                {...register("mobileNumber", {
                  required: true,
                  maxLength: 255,
                })}
                rules={{
                  validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    placeholder={regLabels.mobileNumber}
                    value={value}
                    onChange={onChange}
                    defaultCountry={"GE"}
                    id="mobileNumber"
                  />
                )}
              />
              {errors["mobileNumber"] && (
                <p className="error-message">Invalid phone number</p>
              )}
            </div>

            <div className="form-group text-left">
              <input
                type="password"
                name="password"
                className={`form-control ${
                  errors && errors.password ? "error" : ""
                }`}
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 30,
                })}
                id="password"
                placeholder={regLabels.passwordLabel}
                // defaultValue={""}
                value={password}
                onChange={handleOnChange}
              />
              <p style={{fontSize:'12px'}}>{err && showErrMsg(err)}</p>
            </div>

            <div className="form-group text-left">
              <input
                type="password"
                name="repeatPassword"
                className={`form-control ${
                  errors && state.repeatPassword !== state.password
                    ? "error"
                    : ""
                }`}
                {...register("repeatPassword", {
                  required: true,
                  minLength: 8,
                  maxLength: 30,
                })}
                id="repeat-password"
                placeholder={regLabels.confirmPasswordLabel}
                // defaultValue={""}
                value={repeatPassword}
                onChange={handleOnChange}
              />
              <p style={{fontSize:'12px'}}>{err && showErrMsg(err)}</p>

              {errors["repeatPassword"] && (
                <p className="error-message">{regLabels.passwordErrorLabel}</p>
              )}
            </div>
            <div className="form-group text-left"></div>

            {/* <div className="form-group text-left">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                onChange={onRecaptchaChange}
              />
            </div> */}

            {/* <div className="row no-gutters">
                      <div className="col-9">
                        <div className="custom-control custom-checkbox post-job-chckbox">
                          <input type="checkbox" className="custom-control-input" id="customCheck1" {...register("termsAccepted", {
                            required: "Required",
                          })} />
                          <label className="custom-control-label slide-three" htmlFor="customCheck1">ვეთანხმები წესებს და პირობებს</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <p className="read-terms" data-toggle="modal" data-target="#staticBackdrop">წაკითხვა</p>
                      </div>
                    </div> */}

            <input
              type="hidden"
              {...register("address", { setValueAs: (v) => "dddddsa" })}
            />
            <input
              type="hidden"
              {...register("description", { setValueAs: (v) => "dddddsa" })}
            />
            <input
              type="hidden"
              {...register("livingAddress", { setValueAs: (v) => "dddddsa" })}
            />
            <input
              type="hidden"
              {...register("district", { setValueAs: (v) => "dddddsa" })}
            />
            <input
              type="hidden"
              {...register("idNumber", { setValueAs: (v) => "12345678919" })}
            />

            <div className="row">
              <div className="col-12 pt-3">
                <button
                  className="btn btn-primary btn-block post-job-btn-lg   "
                  // disabled={disabled}
                  onClick={() => onSubmite(getValues())}
                  type="button"
                >
                  გამოქვეყნება
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AfterJobPostRegistration;
