import "./notifications.scss";

import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import NotificationItem from "./notificationItem";
import { API_BASE_URL } from "../../constants/apiConstants";
import { useHistory, useLocation } from 'react-router-dom';

const Notifications = (props) => {
  const [notoficationData, setNotificationData] = useState([]);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const history = useHistory();
  useEffect(() => {
    getNotifications();
  }, []);

  const getNotificationUrl = props.isCustomer ? `${API_BASE_URL}/api/cs/notifications/get` : `${API_BASE_URL}/api/sp/notifications/get`;

  const h_axios = axios.create({
    withCredentials: false,
    baseURL: getNotificationUrl,
    headers: {
      Authorization: `Bearer ${cookies.ContactiAccessToken}`,
    },
  });

  const getNotifications = async () => {
    try {
      const resp = await h_axios.get(getNotificationUrl);
      console.log("KLKLKLKLKLKLKLK",resp)
      setNotificationData(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  const markAsReadNotificationUrl = `${API_BASE_URL}/api/cs/notifications/mark-read `;

  const h_axios_r = axios.create({
    withCredentials: false,
    baseURL: markAsReadNotificationUrl,
    headers: {
      Authorization: `Bearer ${cookies.ContactiAccessToken}`,
    },
  });

  const handleReadNotification = async (id) => {
    try {
      await h_axios_r.post(`${markAsReadNotificationUrl}`, {
        "Id": id,
      });

    } catch (err) {
      console.error(err);
    }
  };

  const markAsRead = (id, JobPostId) => {
    handleReadNotification(id)
    if (JobPostId) {
      console.log("asd")
      window.location.href ="/customer/customer-jobs/" + JobPostId;
    }
  };

  return (
    <div className="notification-cont">
      <div className="notification-cont-title">შეტყობინებები</div>
      {notoficationData.length > 0 ?
        notoficationData.map((item, index) => {
          return (
            <NotificationItem
              key={index}
              Date={item.Date}
              Id={item.Id}
              JobPostId={item.JobPostId}
              Message={item.Message}
              Seen={item.Seen}
              onclick={markAsRead}
            />
          );
        }) : <div className="no__notification__message">თქვენ არ გაქვთ ახალი შეტყობინებები!</div>}
    </div>
  );
};

export default Notifications;
